import React from "react"
import {graphql} from "gatsby";
import PageHeader from "../components/pageheader";

interface Props {
    data: {
        site: {
            siteMetadata: {
                title: string
            }
        }
    }
}

export default function Home(props: Props) {
    return (
        <PageHeader title={"Under Construction"} subtitle={"Come back soon!"} />
    );
}

export const query = graphql`
    query {
        site {
            siteMetadata {
                title
            }
        } 
    }
`