import React from "react"
import {Helmet} from "react-helmet"
import headerStyles from "./pageheader.module.css"
import PageTitle from "./pagetitle";
import PageSubtitle from "./pagesubtitle";

interface Props {
    title: string,
    subtitle: string
}

export default function PageHeader({ title, subtitle }: Props) {
    return (
        <>
            <Helmet title="Horlabs" defer={false} />
            <div className={headerStyles.outer}>
                <div className={headerStyles.inner}>
                    <PageTitle title={title}/>
                    <PageSubtitle subtitle={subtitle}/>
                </div>
            </div>
        </>
    );
}
