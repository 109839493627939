import React from "react"
import headerStyles from "./pagetitle.module.css"

interface Props {
    title: string
}

export default function PageTitle({ title }: Props) {
    return <h2 className={headerStyles.title}>{title}</h2>
}
