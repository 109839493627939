import React from "react"
import headerStyles from "./pagetitle.module.css"

interface Props {
    subtitle: string
}

export default function PageSubtitle({ subtitle }: Props) {
    return <h3 className={headerStyles.subtitle}>{subtitle}</h3>
}
